import { Security } from 'common/security';
import { Badges } from 'services/badges';
import { I18n } from 'common/i18n';
import { DialogController } from 'aurelia-dialog';
import { Notifier } from 'common/ui';
import { NewInstance } from 'aurelia-dependency-injection';
import { ValidationRules, ValidationController } from 'aurelia-validation';
import { BootstrapFormValidationRenderer } from 'validation/bootstrap-form-validation-renderer';
import moment from 'moment';

export class AllEarnedBadges {
    static inject = [Security, Badges, I18n, DialogController, Notifier, NewInstance.of(ValidationController)];
    _security;
    _badges;
    _i18n;
    dialogController;
    _notifier;
    updated = false;

    titleKey = '';
    titleCaptionKey = 'profile:badges-earned';
    deleteKey = 'delete';

	constructor(security, badges, i18n, dialogController, notifier, validationController) {
        this._security = security;
		this._badges = badges;
        this._i18n = i18n;
        this.dialogController = dialogController;
        this.dialogController.settings.centerHorizontalOnly = true;
        this._notifier = notifier;
        this.validationController = validationController;
		this.validationController.addRenderer(new BootstrapFormValidationRenderer());

        this.isAdmin = this._security.isAdmin;

		ValidationRules
            .ensure('code').required().satisfiesRule('slug').when(x => x.showAddBadge)
            .ensure('name').required().when(x => x.showAddBadge)
            .ensure('color').required().when(x => x.showAddBadge)
            .ensure('icon').required().when(x => x.showAddBadge)
            .ensure('ordering').required().satisfiesRule('integerRange', 1, 999999999)
            .on(this);
	}

    activate(model) {
        this.memberId = model.memberId;
        this.title = model.title;
        this.badgeType = model.badgeType;
        this.badges = model.badges;
    }

    close() {
        this.dialogController.ok({ updated: this.updated });
    }

    async unassignBadgeFromMember(index, badge) {
        try {
            this.unassigning = true;
            await this._badges.unassign(this.memberId, badge.code);
            this.badges.splice(index, 1);
            this.updated = true;
        } catch (err) {
            console.log(err);
        } finally {
            this.unassigning = false;
        }
    }

    async assignBadge() {
        try {
            this.showAssignBadge = true;
            this.unassignedBadges = await this._badges.unassigned(this.badgeType, this.memberId);
            this.titleCaptionKey = 'profile:assign-new-badge';
        } catch (err) {
            console.log(err);
        }
    }

    cancelAssignBadge() {
        this.showAssignBadge = false;
        this.titleKey = undefined;
        this.titleCaptionKey = 'profile:badges-earned';
    }

    async assignBadgeToMember(index, badge) {
        try {
            this.assigning = true;
            await this._badges.assign(this.memberId, badge.code);
            this.unassignedBadges.splice(index, 1);
            const newBadge = {
                code: badge.code,
                memberId: this.memberId,
                assignedDate: moment().format(),
                badgeType: badge.badgeType,
                icon: badge.icon,
                color: badge.color,
                ordering: badge.ordering,
            };
            this.badges.push(newBadge);
            this.badges.sort((a,b) => a.ordering - b.ordering);
            this.updated = true;
        } catch (err) {
            console.log(err);
        } finally {
            this.assigning = false;
        }
    }

    addBadge() {
        this.showAddBadge = true;
        this.titleCaptionKey = undefined;
        this.titleKey = 'profile:add-new-badge';
    }

    async saveBadge() {
        try {
            const v = await this.validationController.validate();
            if (!v.valid) return;
            let icon = this.icon;
            if (this.icon.indexOf('<i') === 0) {
                const quoteIndex = this.icon.indexOf('"');
                const lastQuoteIndex = this.icon.lastIndexOf('"');
                icon = icon.substr(quoteIndex + 1, lastQuoteIndex - quoteIndex - 1);
            }
            const newBadgeDefinition = await this._badges.saveDefinition(this.badgeType, this.code, this.name, this.color, icon, this.ordering);
            await this._i18n.reloadTranslations();
            if (this.editBadgeIndex !== undefined) {
                if (this.editBadgeList === 'add') {
                    this.unassignedBadges[this.editBadgeIndex] = newBadgeDefinition;
                    this.unassignedBadges.sort((a, b) => a.ordering - b.ordering);
                } else if (this.editBadgeList === 'assign') {
                    this.badges[this.editBadgeIndex].color = newBadgeDefinition.color;
                    this.badges[this.editBadgeIndex].name = newBadgeDefinition.name;
                    this.badges[this.editBadgeIndex].icon = newBadgeDefinition.icon;
                    this.badges[this.editBadgeIndex].ordering = newBadgeDefinition.ordering;
                    this.badges.sort((a, b) => a.ordering - b.ordering);
                    this.showAssignBadge = false;
                }
            } else {
                this.unassignedBadges.push(newBadgeDefinition);
                this.unassignedBadges.sort((a, b) => a.ordering - b.ordering);
            }
            this.cancelAddBadge();
            this.updated = true;
        } catch (err) {
            console.log(err);
        }
    }

    cancelAddBadge() {
        this.showAddBadge = false;
        this.editBadgeCode = undefined;
        this.editBadgeIndex = undefined;
        this.editBadgeList = undefined;
        this.titleCaptionKey = this.showAssignBadge ? 'profile:assign-new-badge' : 'profile:earned-badges';
        this.titleKey = undefined;
        this.code = undefined;
        this.name = undefined;
        this.color = undefined;
        this.icon = undefined;
        this.ordering = undefined;
    }

    editBadge(index, badge, list) {
        this.showAssignBadge = true;
        this.showAddBadge = true;
        this.code = badge.code;
        this.name = this._i18n.tr(`profile:badge-${badge.code}`);
        this.color = badge.color;
        this.icon = badge.icon;
        this.ordering = badge.ordering;
        this.editBadgeCode = badge.code;
        this.editBadgeIndex = index;
        this.editBadgeList = list;
        this.titleCaptionKey = undefined;
        this.titleKey = 'profile:edit-badge';
    }

    resetDelete() {
        this.showDeleteConfirm = false;
        this.deleteKey = 'delete';
    }

    async deleteBadgeDefinition() {
        if (!this.showDeleteConfirm) {
            this.showDeleteConfirm = true;
            this.deleteKey = 'delete-confirm';
            return;
        }

        this.showDeleteConfirm = false;
        if (this.deleting) return;
        try {
            this.deleting = true;
            const response = await this._badges.deleteDefinition(this.editBadgeCode);
            if (this.editBadgeList === 'add') {
                this.unassignedBadges.splice(this.editBadgeIndex, 1);
            } else if (this.editBadgeList === 'assign') {
                this.badges.splice(this.editBadgeIndex, 1);
                this.showAssignBadge = false;
            }
            this.cancelAddBadge();
            this.updated = true;
        } catch(err) {
            this.resetDelete();
            this._notifier.generalError();
        } finally {
            this.deleting = false;
        }
    }
}
