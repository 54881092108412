import { Security } from 'common/security';

export class AdminToggleToolsButton {
	static inject = [Security];

	constructor(security) {
		this.showAdminToggle = security.showAdminToggle();
	}

	toggleAdminTools() {
	    this.adminToolsOn = !this.adminToolsOn;
	    if (this.adminToolsOn) $('body').addClass('admin-tools-on'); else $('body').removeClass('admin-tools-on');
	}
}