import { Badges } from 'services/badges';

export class BadgeAssignments {
    static inject = [Badges];
    _badges;

    badges;
    agents;

    constructor(badges) {
        this._badges = badges;
    }

    attached() {
        this._initialize();
    }

    async _initialize() {
        try {
            this.badges = await this._badges.all();
        } catch (err) {
            console.log(err);
        }
    }

    async selectBadge(badge) {
        try {
            this.badge = badge;
            this.loading = true;
            this.agents = undefined;
            this.agents = await this._badges.assigned('agent', this.badge.code);
        } catch (err) {
            console.log(err);
        } finally {
            this.loading = false;
        }
    }
}
