import { observable } from 'aurelia-framework';
import { Google } from 'services/google';

export class AddressAutocomplete {
    static inject = [Element, Google];
    _element;
    _google;

    @observable fullAddress;

    google;
    addressAutocompleteEl;
    autocomplete;
    componentForm = {
        street_number: 'short_name',
        route: 'long_name',
        locality: 'long_name',
        sublocality: 'long_name',
        sublocality_level_1: 'long_name',
        administrative_area_level_1: 'short_name',
        country: 'long_name',
        postal_code: 'short_name',
        postal_code_suffix: 'short_name'
    };

	constructor(element, google) {
        this._element = element;
        this._google = google;
    }

	async attached() {
        this._initializePlacesAutocomplete();
    }

    fullAddressChanged() {
        this._element.dispatchEvent(new CustomEvent(this.fullAddress ? 'selecting' : 'clear', { bubbles: true, detail: {} }));
    }

    async _initializePlacesAutocomplete() {
        try {
            await this._google.load();
            this.autocomplete = new google.maps.places.Autocomplete((this.addressAutocompleteEl), { types: ['geocode'] });
            this.autocomplete.addListener('place_changed', () => this.fillInAddress());
            this.geolocate();
        } catch (err) {
            console.log('GOOGLE initAutocomplete error', err);
        }
    }

    /**
     * Bias the autocomplete object to the user's geographical location, as supplied by the browser's 'navigator.geolocation' object.
     */
    geolocate() {
        try {
            if (!navigator.geolocation) return;
            navigator.geolocation.getCurrentPosition((position) => {
                const geolocation = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                };
                const circle = new google.maps.Circle({
                    center: geolocation,
                    radius: position.coords.accuracy
                });
                this.autocomplete.setBounds(circle.getBounds());
            });
        } catch (err) {
            console.log('GEOLOCATE ERR', err);
        }
    }

    fillInAddress() {
        if (!this.autocomplete) return;
        try {
            // Get the place details from the autocomplete object.
            const place = this.autocomplete.getPlace();
        
            const address = {};

            let streetNumber = '';
            let street = '';
            let sublocality = '';
            for (let i = 0; i < place.address_components.length; i++) {
                var addressType = place.address_components[i].types[0];
                if (this.componentForm[addressType]) {
                    const val = place.address_components[i][this.componentForm[addressType]];
                    switch (addressType) {
                        case 'street_number': streetNumber = val; break;
                        case 'route': street = val; break;
                        case 'locality': address.city = val; break;
                        case 'sublocality':
                        case 'sublocality_level_1': sublocality = val; break;
                        case 'administrative_area_level_1': address.state = val; break;
                        case 'postal_code': address.zip = val; break;
                    }
                }
            }
            if (!address.city && sublocality) address.city = sublocality;
            address.streetAddress = `${streetNumber} ${street}`;
            this._element.dispatchEvent(new CustomEvent('selected', { bubbles: true, detail: address }));
        } catch (err) {
            console.log('GOOGLE fill in address error', err);
        }
    }
} 
