import { bindable } from 'aurelia-framework';
import { Accordion } from './accordion';
import { LpfnUtil } from 'common/utils';

export class AccordionSection {
    static inject = [Accordion];
    accordion;
    @bindable title;
  
    id = LpfnUtil.getUniqueId();

    constructor(accordion) {
        this.accordion = accordion;
    }
  
    attached() {
        this.accordion.sections.push(this);
    }

    toggle() {
        try {
            if (LpfnUtil.hasClass(this.accordionSectionEl, 'show')) {
                LpfnUtil.addClass(this.accordionSectionEl, 'hiding');
                LpfnUtil.slideUp(this.accordionSectionSubEl, 350, () => {
                    LpfnUtil.removeClass(this.accordionSectionEl, 'hiding');
                    LpfnUtil.removeClass(this.accordionSectionEl, 'show');
                    LpfnUtil.removeClass(this.accordionSectionSubEl, 'show');
                    LpfnUtil.removeClass(this.accordionSectionEl, 'hover');
                });
            } else {
                LpfnUtil.addClass(this.accordionSectionEl, 'hover');
                LpfnUtil.addClass(this.accordionSectionEl, 'showing');
                LpfnUtil.slideDown(this.accordionSectionSubEl, 350, () => {
                    LpfnUtil.removeClass(this.accordionSectionEl, 'showing');
                    LpfnUtil.addClass(this.accordionSectionEl, 'show');
                    LpfnUtil.addClass(this.accordionSectionSubEl, 'show');
                });
            }
        } catch (err) {
            console.log(err);
        }
    }

    showContent() {
        if (this.isVisible) {
            this.isVisible = false;
            return;
        }

        for (let section of this.accordion.sections) {
            section.isVisible = false;
        }
        this.isVisible = true;
    }
}