import { bindable, observable } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { createGrid } from 'ag-grid-community';
import { LicenseManager } from 'ag-grid-enterprise';
import moment from 'moment';
import { c } from 'common/common';
import { mode } from 'common/ui';

export class AnalyticsGrid {
    static inject = [Element, EventAggregator];
    _element;
    _ea;

    @bindable cols;
    @bindable data;
    @bindable groupByColumn;
    @bindable selectable = false
    @bindable showHeader = false;
    @bindable showQuickFilter = false;
    @bindable advanced = false;

    theme = 'ag-theme-alpine';
    @observable quickFilter;

    _handlers = [];

    constructor(element, ea) {
        this._element = element;
        this._ea = ea;
        LicenseManager.setLicenseKey('Using_this_AG_Grid_Enterprise_key_( AG-048923 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Legacy Partners Financial Network )_is_granted_a_( Multiple Applications )_Developer_License_for_( 1 ))_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_AG_Grid_Enterprise___This_key_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 8 October 2024 )____[v2]_MTcyODM0MjAwMDAwMA==6c25442b65b7684c1bce1e644f18f2ad');
    }

    attached() {
        this._handlers.push(this._ea.subscribe(c.EventKeys.site.modeChanged, (data) => this._setTheme(data)));
        this._setTheme();
        this._initializeGrid();
        this._resizeGrid();
    }

    groupByColumnChanged() {
        if (!this._agGrid) return;
        try {
            const columnDefs = this._agGrid.getColumnDefs();
            const groupedColumns = columnDefs.filter(x => x.rowGroup);
            groupedColumns.forEach(gc => {
                gc.rowGroup = false;
                gc.rowGroupIndex = null;
                gc.hide = false;
            });
            const groupByIndex = columnDefs.findIndex(x => x.field === this.groupByColumn);
            if (groupByIndex >= 0) {
                columnDefs[groupByIndex].rowGroup = true;
                columnDefs[groupByIndex].rowGroupIndex = 0;
                columnDefs[groupByIndex].hide = true;
            }
            this._agGrid.setGridOption('columnDefs', columnDefs);
        } catch (err) {
            console.log(err);
        }
    }

    _setTheme(data) {
        if (!data) data = mode();
        if (data.mode === 'dark') this.theme = 'ag-theme-alpine-dark';
        else this.theme = 'ag-theme-alpine';
    }

    _resizeGrid() {
        try {
            const containerEl = document.getElementById('kt_app_content');
            this.gridHeight = containerEl.clientHeight - 80; // 50 for the heading div
        } catch (err) {
            console.log(err);
            this.gridHeight = 500;
        }
    }

    colsChanged() {
        if (this._agGrid) {
            this._agGrid.destroy();
            this._agGrid = undefined;
        }
        this._initializeGrid();
    }

    dataChanged() {
        if (!this._agGrid) return;
        const hasSelectedNodes = this._agGrid.getSelectedNodes().length > 0;
        this._agGrid.setGridOption('rowData', this.data);
        if (hasSelectedNodes) this._dispatchSelectedEvent([]);
    }

    quickFilterChanged() {
        if (!this._agGrid) return;
        this._agGrid.setGridOption('quickFilterText', this.quickFilter);
    }

    _initializeGrid() {
        if (!this.cols || !this.analyticsGridEl) return;
        try {
            const includeSums = this.cols.find(x => x.aggFunc === 'sum') != undefined;
            const gridOptions = {
                columnDefs: this.cols,
                defaultColDef: {
                    sortable: true,
                    filter: true,
                    editable: false,
                    filter: 'agMultiColumnFilter',
                    floatingFilter: false,
                    resizable: true,
                },
                autoGroupColumnDef: {
                    minWidth: 200,
                },
                animateRows: true,
                groupDisplayType: 'multipleColumns',
                //rowGroupPanelShow: 'always',
                groupDefaultExpanded: 0,
                groupTotalRow: includeSums,
                grandTotalRow: includeSums,
                //suppressAggFuncInHeader: true,
                sideBar: {
                    defaultToolPanel: undefined,
                    toolPanels: [ 'columns' ]
                },
                //groupAggFiltering: true,
                columnTypes: {
                    dateColumn: {
                        filter: 'agDateColumnFilter',
                        filterParams: { comparator: this._dateComparator },
                    },
                },
                dataTypeDefinitions: {
                    dateString: {
                        baseDataType: 'dateString',
                        extendsDataType: 'dateString',
                        valueFormatter: (params) => {
                            return !params.value
                                ? ''
                                : moment(params.value).format('MM/DD/yyyy');
                        },
                    },
                    percentage: {
                        extendsDataType: 'number',
                        baseDataType: 'number',
                        valueFormatter: params => params.value == null
                            ? ''
                            : `${Math.round(params.value * 100)}%`,
                    },
                    money: {
                        extendsDataType: 'number',
                        baseDataType: 'number',
                        valueFormatter: params => params.value == null
                            ? ''
                            : `$${Number(params.value).formatMoney(0)}`,
                    },
                }
            };

            if (this.advanced) {
                gridOptions.enableAdvancedFilter = true;
            }

            if (this.selectable) {
                gridOptions.rowSelection = 'multiple';
                gridOptions.groupSelectsChildren = true;
                gridOptions.groupSelectsFiltered = true;
                gridOptions.suppressRowClickSelection = true;

                gridOptions.onSelectionChanged = (event) => {
                    try {
                        const nodes = event.api.getSelectedNodes();
                        const selectedIds = nodes.map(x => x.data.id);
                        this._dispatchSelectedEvent(selectedIds);
                    } catch (err) {
                        console.log(err);
                    }
                };
            }

            if (this.data) gridOptions.rowData = this.data;
      
            this._agGrid = createGrid(this.analyticsGridEl, gridOptions);
        } catch (err) {
            console.log(err);
        }
    }

    _dispatchSelectedEvent(ids) {
        this._element.dispatchEvent(new CustomEvent('selected', { bubbles: true, detail: { ids } }));
    }

    _dateComparator = (filterLocalDateAtMidnight, cellValue) => {
        const dateAsString = cellValue;
        if (!dateAsString) return 0;

        var cellMoment = moment(dateAsString);
        const year = cellMoment.year();
        const month = cellMoment.month();
        const day = cellMoment.date();
        const cellDate = new Date(year, month, day);

        // Now that both parameters are Date objects, we can compare
        if (cellDate < filterLocalDateAtMidnight) {
            return -1;
        } else if (cellDate > filterLocalDateAtMidnight) {
            return 1;
        }
        return 0;
    }
}