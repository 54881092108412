import { inject, customElement, bindable } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { AgentSites } from 'services/agent-sites';
import { I18n } from 'common/i18n';
import { c } from 'common/common';

@customElement('agent-site-content')
@inject(EventAggregator, AgentSites, I18n)
export class AgentSiteContent {
    id = '';
    @bindable key;
    @bindable hideTitle = false;
    uiWidget = null;
    isAdmin = false;

    constructor(ea, agentSites, i18n) {
        this._ea = ea;
        this._agentSites = agentSites;
        this._i18n = i18n;

        this.id = c.Helpers.uniqueId();
    }

    bind() {
        if (!this.key) return;
        this._loadUiWidget();
    }

    keyChanged(newValue, oldValue) {
        if (!newValue) return;
        this._loadUiWidget();
    }

    attached() {
        window.setTimeout(() => { this._initializeEmbeddedContent(); }, 250);

        this._reloadHandler = this._ea.subscribe('lpfn-reload-content', () => {
            this._loadUiWidget();
        });
    }

    detached() {
        this._reloadHandler.dispose();
        this._reloadHandler = undefined;
    }

    async _loadUiWidget() {
        if (!this.key) return null;
        const content = await this._agentSites.contentFor(this.key);
        if (!content) {
            this.uiWidgets = [];
            return;
        }
        if (content.uiWidgetsInOrder) {
            this.uiWidgets = content.uiWidgetsInOrder;
        } else {
            this.uiWidgets = [content];
        }
    }

    _initializeEmbeddedContent() {
    }
}
