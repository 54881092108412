export class LinkCallbackRenderer {
    init(params) {
        try {
            this.eGui = document.createElement('div');
            if (params.data && params['lpfnCallback']) this.onClick = params.data[params['lpfnCallback']];
            if (!this.onClick) {
                // no callback, just display the info
                this.cellValue = this.getValueToDisplay(params);
                this.eGui.innerHTML = this.cellValue;
                return;
            }
            this.eGui.innerHTML = `<a href="#" class="lpfn-pnr-link">${this.getValueToDisplay(params)}</a>`;
            this.eTag = this.eGui.querySelector('.lpfn-pnr-link');
            this.cellValue = this.getValueToDisplay(params);
            this.eTag.innerHTML = this.cellValue;
            this.eventListener = (ev) => {
                try {
                    if (this.onClick) this.onClick();
                    ev.stopPropagation();
                    ev.preventDefault();
                } catch (err) {
                    console.log(err);
                } finally {
                    return false;
                }
            };
            this.eTag.addEventListener('click', this.eventListener);
        } catch (err) {
            console.log(err);
        }
    }

    getGui() {
        return this.eGui;
    }

    refresh(params) {
        try {
            this.cellValue = this.getValueToDisplay(params);
            if (!this.onClick) this.eGui.innerHTML = this.cellValue;
            else this.eTag.innerHTML = this.cellValue;
            return true;
        } catch (err) {
            console.log(err);
        } finally {
            return true;
        }
    }

    destroy() {
        if (this.eTag) {
            this.eTag.removeEventListener('click', this.eventListener);
        }
    }

    getValueToDisplay(params) {
        return params.valueFormatted ? params.valueFormatted : params.value ?? '';
    }
}

export function LinkCellRenderer(params) {
    try {
        let dataValue = params.value;
        if (!dataValue) return '';
        let formattedUrl = params['urlFormatter'] ? params.urlFormatter(dataValue) : dataValue;
        let link = `<a href="${formattedUrl}" target="_blank">${dataValue}</a>`;
        return link;
    } catch (err) {
        console.log(err);
        return '';
    }
}

export class ButtonCallbackRenderer {
    init(params) {
        try {
            this.eGui = document.createElement('div');
            if (params.data && params['lpfnCallback']) this.onClick = params.data[params['lpfnCallback']];
            this.eGui.innerHTML = `<button class="btn btn-outline-secondary btn-xs lpfn-pnr-button">${params.buttonText}</button>`;
            this.eTag = this.eGui.querySelector('.lpfn-pnr-button');
            this.cellValue = this.getValueToDisplay(params);
            this.eTag.innerHTML = this.cellValue;
            this.eventListener = (ev) => {
                try {
                    if (this.onClick) this.onClick();
                    ev.stopPropagation();
                    ev.preventDefault();
                } catch (err) {
                    console.log(err);
                } finally {
                    return false;
                }
            };
            this.eTag.addEventListener('click', this.eventListener);
        } catch (err) {
            console.log(err);
        }
    }

    getGui() {
        return this.eGui;
    }

    refresh(params) {
        try {
            return true;
        } catch (err) {
            console.log(err);
        } finally {
            return true;
        }
    }

    destroy() {
        if (this.eTag) {
            this.eTag.removeEventListener('click', this.eventListener);
        }
    }

    getValueToDisplay(params) {
        return params.buttonText;
    }
}
