import { bindable, computedFrom } from 'aurelia-framework';

export class Address {
    @bindable data;
    @bindable showMap = false;
    @bindable showAddress = true;
    @bindable showOptions = true;
    @bindable view;
    @bindable enlargeMap;
    @bindable zoom;

    mapView = 'place';

    @computedFrom('data')
    get hasAddress() {
        if (!this.data) return false;
        return (this.data.address || this.data.streetAddress || this.data.line1 || this.data.addressLine1) && this.data.city && this.data.state && (this.data.zip || this.data.zipCode);
    }

    @computedFrom('data')
    get mapAddress() {
        let address = this.data.line1 
            ? this.data.line1
            : this.data.address 
                ? this.data.address
                : this.data.streetAddress;
        if (!address) address = this.data.addressLine1;
        return encodeURIComponent(`${address},${this.data.city},${this.data.state} ${this.data.zip || this.data.zipCode}`);
    }

    @computedFrom('data')
    get mapLatLon() {
        if (!this.data) return null;
        if (!this.data.point || !this.data.point.latitude || !this.data.point.longitude) return null;
        return `${this.data.point.latitude},${this.data.point.longitude}`;
    }

    @computedFrom('data')
    get mapPlaceId() {
        if (!this.data || !this.data.point) return null;
        return this.data.point.googlePlaceId;
    }

    viewChanged() {
        if (!this.view) return;
        this.setMapView(this.view);
    }

    setMapView(view) {
        this.mapView = view;
    }

    enlargeMapChanged() {
        if (!this.enlargeMap) return;
        this.enlargeGoogleMap = this.enlargeMap;
    }

    enlargeView() {
        this.enlargeGoogleMap = new Date().valueOf();
    }
}
