import { bindable } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Security } from 'common/security';

export class AgentSidebarButton {
    static inject = [EventAggregator, Security];

    _ea;
    security;

    @bindable collapsed;

    taskCount = 0;
    messageCount = 0;

    constructor(ea, security) {
        this._ea = ea;
        this.security = security;
    }
}
