import { bindable } from 'aurelia-framework';
import { Notes } from 'services/notes';

export class AddPolicyNote {
    static inject = [Element, Notes];
    _element;
    _notes;

    @bindable policy;
    policyNote;

    constructor(element, notes) {
        this._element = element;
        this._notes = notes;
    }

    policyChanged() {
        this.policyNote = undefined;
        this.focusNote = false;
        if (this.policy) {
            window.setTimeout(() => this.focusNote = true, 500);
        }
    }

    async saveComment() {
        try {
            if (!this.policyNote) return;
            await this._notes.save(undefined, 'Policy', this.policy.id, this.policyNote);
            this._element.dispatchEvent(new CustomEvent('saved', { bubbles: true, detail: {} }));
        } catch (err) {
            console.log(err);
        }
    }
}
