import { PLATFORM } from 'aurelia-pal';
import { bindable } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { DialogService } from 'aurelia-dialog';
import { Sites } from 'services/sites';
import { EditAgentProfileImageDialog } from './../../../members/agents/dialogs/edit-agent-profile-image-dialog';
import { c } from 'common/common';
import environment from '../../../../config/environment.json';
PLATFORM.moduleName('./../../../members/agents/dialogs/edit-agent-profile-image-dialog');

export class AgentAvatar {
    static inject = [EventAggregator, DialogService, Sites];
    _ea;
    _dialogService;
    _sites;

    @bindable id = '';
    @bindable agent;
    @bindable size = 'sm'
    @bindable cssClass;
    @bindable editable = false;
    @bindable useLogo = false;
    @bindable score;

    imgEl;

    _handlers = [];

    constructor(ea, dialogService, sites) {
        this._ea = ea;
        this._dialogService = dialogService;
        this._sites = sites;
    }

    bind() {
        this._setSize();
        if (this.useLogo) this._setLogo();
    }

    async _setLogo() {
        try {
            const theme = await this._sites.theme();
            this.imgSrc = `${environment.static}/files/themes/${theme.key}/favicons/favicon-96x96.png`;
        } catch (err) {
            console.log(err);
        }
    }

    scoreChanged() {
        try {
            if (!this.score) return;
            if (this.score < 10) this.formattedScore = c.Helpers.formatNumber(this.score, 1);
            else this.formattedScore = c.Helpers.formatNumber(this.score, 0);
            if (this.formattedScore.toString().length >= 4) this.scoreClass = 'smaller';
            else this.scoreClass = '';
        } catch (err) {
            console.log(err);
        }
    }

    agentChanged() {
        this._setAgent();
    }

    sizeChanged() {
        this._setSize();
    }

    attached() {
        try {
            this.labelEl.style.display = 'none';
            this._handlers.push(this._ea.subscribe(c.EventKeys.agent.profileImageUpdated, (data) => this._imageUpdated(data.agentId)));
            this._setSize();
            this._setAgent();
        } catch (err) {
            console.log(err);
        }
    }

    detached() {
        this._handlers.forEach(h => h.dispose());
        this._handlers = [];
    }

    _imageUpdated(agentId) {
        try {
            if (!this.agent || !agentId) return;
            if (this.agent.id.toLowerCase() !== agentId.toLowerCase()) return;
            this.imgEl.src = 'about:blank';
            window.setTimeout(() => {
                this.imgSrc = `${environment.static}/memberzone/profile/${this.agent.id}-t.jpg?v=${new Date().getTime()}`;
                this.imgEl.src = this.imgSrc;
                this.imgEl.style.display = 'inline-block';
                this.labelEl.style.display = 'none';
            }, 500);
        } catch (err) {
            console.log(err);
        }
    }

    _setAgent() {
        if (!this.agent) return;
        try {
            if (!this.useLogo) {
                this.imgSrc = `${environment.static}/memberzone/profile/${this.agent.id}-t.jpg`;
            }
            let fullName = this.agent.fullName;
            if (this.agent.firstName && this.agent.lastName) {
                this.initials = `${this.agent.firstName.substr(0, 1)}${this.agent.lastName.substr(0, 1)}`.toUpperCase();
                if (!fullName) fullName = `${this.agent.firstName} ${this.agent.lastName}`;
            } else if (this.agent.fullName) {
                const names = this.agent.fullName.split(' ');
                if (names.length >= 2) this.initials = `${names[0].substr(0, 1)}${names[1].substr(0, 1)}`.toUpperCase();
                else this.initials = this.agent.fullName.substr(0, 1).toUpperCase();
            } else this.initials = this.agent.fullName || '';
            this.colors = c.Helpers.stringToColor(fullName);
        } catch (err) {
            console.log(err);
        }
    }

    _setSize() {
        if (!this.size) {
            this.sizeCss = 'symbol-40px';
            return;
        }
        switch (this.size) {
            case 'xxs': this.sizeCss = 'symbol-20px'; break;
            case 'xs': this.sizeCss = 'symbol-25px'; break;
            case 'sm': this.sizeCss = 'symbol-40px'; break;
            case 'md': this.sizeCss = 'symbol-65px'; break;
            case 'ml': this.sizeCss = 'symbol-75px'; break;
            case 'lg': this.sizeCss = 'symbol-75px symbol-lg-125px'; break;
            case 'xl': this.sizeCss = 'symbol-125px symbol-sm-60px symbol-lg-60px symbol-xl-100px symbol-xxl-125px'; break;
            default: this.sizeCss = 'symbol-40px'; break;
        }
    }

    onImageError() {
        try {
            this.style.onerror = null;
            this.style.display = 'none';
            const labelEl = this.parentNode.querySelector('.symbol-label');
            labelEl.style.display = 'flex';
        } catch (err) {
            console.log(err);
        }
    }

    openProfileImageEditor() {
        this._dialogService.open({ viewModel: EditAgentProfileImageDialog, model: this.agent, ignoreTransitions: true });
    }
}