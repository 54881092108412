import { PLATFORM } from 'aurelia-pal';
import { bindable } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Security } from 'common/security';
import { Badges } from 'services/badges';
import { AllEarnedBadges } from './dialogs/all-earned-badges';
import { DialogService } from 'aurelia-dialog';
import { LpfnUtil } from 'common/utils';
import { c } from 'common/common';
PLATFORM.moduleName('./dialogs/all-earned-badges');

export class EarnedBadges {
    static inject = [Element, EventAggregator, Security, Badges, DialogService];
    _element;
    _ea;
    _security;
    _badges;
    _dialogService;

    id;
    @bindable memberId;
    @bindable badgeType = 'agent';
    @bindable size = 'md';
    @bindable max = 5;

    _intersectionHandler;
    _handlers = [];

    constructor(element, ea, security, badges, dialogService) {
        this._element = element;
        this._ea = ea;
        this._security = security;
        this._badges = badges;
        this._dialogService = dialogService;
        this.id = c.Helpers.uniqueId();
    }

    attached() {
        this._isAttached = true;
        this._clearHandlers();
        this._addHandlers();
        this._intersectionHandler = LpfnUtil.inViewportHandler(this._element, (isVisible) => {
            if (!isVisible) return;
            this._load();
            this._clearIntersectionObserver();
        });
    }

    _clearIntersectionObserver() {
        if (!this._intersectionHandler) return;
        this._intersectionHandler.dispose();
        this._intersectionHandler = undefined;
    }

    detached() {
        this._clearIntersectionObserver();
        this._clearHandlers();
    }

    _addHandlers() {
        if (!this.memberId) return;
        this._handlers.push(this._ea.subscribe(`lpfn-badge-updated-${this.memberId}`, () => {
            this._loadComplete = false;
            this._load();
        }));
    }

    _clearHandlers() {
        this._handlers.forEach(h => h.dispose());
        this._handlers = [];
    }

    memberIdChanged() {
        if (!this.memberId || !this._isAttached) return;
        this._clearHandlers();
        this._addHandlers();
        this._loadComplete = false;
        this._load();
    }

    badgeTypeChanged() {
        if (!this.badgeType || !this._isAttached) return;
        this._clearHandlers();
        this._addHandlers();
        this._loadComplete = false;
        this._load();
    }

    async _load() {
        if (this._loadComplete) return;
        if (!this.memberId || !this.badgeType) return;
        this._loadComplete = true;
        try {
            this.loading = true;
            this._allBadges = await this._badges.for(this.badgeType, this.memberId);
            const max = parseInt(this.max);
            if (this._allBadges.badges.length <= max) {
                this.badges = JSON.parse(JSON.stringify(this._allBadges.badges));
            } else {
                // only display max plus 1 with a count
                this.badges = [];
                for (let i = 0; i < max; i++) this.badges.push(this._allBadges.badges[i]);
                this.badges.push({
                    code: null,
                    text: `+${this._allBadges.badges.length - max}`,
                    color: 'black'
                });
            }
            if (this.badges.length === 0 && this._security.isAdmin) this.badges.push({ code: null, text: '0', color: 'lightgray' });
        } catch (err) {
            console.log(err);
        } finally {
            this.loading = false;
        }
    }

    viewAll(ev, badge) {
        if (ev) {
            ev.stopPropagation();
            ev.preventDefault();
        }
        const model = {
            memberId: this.memberId,
            title: this._allBadges.title,
            badgeType: this.badgeType,
            badges: this._allBadges.badges,
            code: badge.code,
        };
	    this._dialogService.open({ viewModel: AllEarnedBadges, model, ignoreTransitions: true }).whenClosed(async(response) => {
	        if (!response.output || !response.output.updated) return;
            this._ea.publish(`lpfn-badge-updated-${this.memberId}`, {});
            this._loadComplete = false;
            this._load();
        });
    }
}
